import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'farmasiphoto-web';
  constructor(private translate: TranslateService) {}

  ngOnInit() {
    if (localStorage.getItem('language_type') || sessionStorage.getItem('language_type')) {
      if  (localStorage.getItem('language_type') === '1' || sessionStorage.getItem('language_type') === '1') {
        this.translate.use('en');
      } else {
        this.translate.use('sp');
      }
    } else {
      localStorage.setItem('language_type', '1');
      localStorage.setItem('setWebLanguage', 'en');
      sessionStorage.setItem('language_type', '1');
      sessionStorage.setItem('setWebLanguage', 'en');
      this.translate.use('en');
    }
  }
}
