import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, Injector } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { CommonHttpService, HeadParInterceptor } from './core/interceptor/header-interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateModule, TranslateService, TranslateLoader } from '@ngx-translate/core';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule
  ],
  providers: [
    CommonHttpService,
    { provide: HTTP_INTERCEPTORS,
      useClass: HeadParInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor(translate: TranslateService) {
    translate.addLangs(['en', 'sp']);
    // translate.use('en');
    // if (localStorage.getItem('setWebLanguage')) {
    //   translate.setDefaultLang(localStorage.getItem('setWebLanguage'));
    // } else {
    //   translate.setDefaultLang('en');
    // }
    // if (sessionStorage.getItem('setWebLanguage')) {
    //   translate.setDefaultLang(sessionStorage.getItem('setWebLanguage'));
    // } else {
    //   translate.setDefaultLang('en');
    // }
    // const browserLang = translate.getBrowserLang();
    // if (localStorage.getItem('setWebLanguage') === 'sp' || sessionStorage.getItem('setWebLanguage') === 'sp') {
    //   translate.use(browserLang.match('/es/') ? browserLang : 'sp');
    // } else {
    //   translate.use(browserLang.match('/en/') ? browserLang : 'en');
    // }
  }
}
