import { Injectable } from "@angular/core";
import { HttpClient, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { tap, catchError } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { ToastrService } from "ngx-toastr";
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()

export class CommonHttpService {
    
    // API_URL = 'https://dev.algoworks.com/flavour/api/';
    // API_URL = 'http://3.82.182.38/flavour/api/';
    API_URL = 'https://backend.flavorfactoryapp.com/flavour/api/';
    
    loading: boolean;

    constructor(private http: HttpClient) { }

    get<T>(url: string): Observable<T> {
        return this.http.get<T>(this.API_URL + url);
    }

    post<T>(url: string, body: any): Observable<T> {
        return this.http.post<T>(this.API_URL + url, body);
    }

    put<T>(url: string, body: any): Observable<T> {
        return this.http.put<T>(this.API_URL + url, body);
    }

    delete<T>(url: string): Observable<T> {
        return this.http.delete<T>(this.API_URL + url);
    }

    patch<T>(url: string, body: any): Observable<T> {
        return this.http.patch<T>(this.API_URL + url, body);
    }

}

@Injectable()

export class HeadParInterceptor implements HttpInterceptor {

    constructor(private _toastr: ToastrService, private spinner: NgxSpinnerService) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler): Observable<HttpEvent<any>> {
        if (localStorage.getItem('farmasi-webtoken')) {
            const req = request.clone({
                headers: new HttpHeaders({
                    Authorization: localStorage.getItem("farmasi-webtoken"),
                    Accept: 'application/json',
                    appId: '3',
                    language: sessionStorage.getItem('language_type') ? sessionStorage.getItem('language_type') : localStorage.getItem('language_type')
                })
            });

            return next.handle(req).pipe(
                tap((event: HttpEvent<any>) => {
                    let err_msg = '';
                    this.spinner.show();
                    if (event instanceof HttpResponse) {
                        if (event.body && event.body.success == true) {
                            this.spinner.hide();
                        }
                        if (event.body && event.body.success == false) {
                            this.spinner.hide();
                            err_msg = event.body.error.msg;
                            this._toastr.error(err_msg);
                        }
                        if (event.body && event.body.FREE) {
                            this.spinner.hide();
                        }
                    }
                }), catchError((err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400) {
                            if (err.error) {
                                this._toastr.error(err.error.error.msg);
                                this.spinner.hide();
                            }
                        }
                        else if (err.status === 401) {
                            localStorage.clear();
                            if (!environment.production) {
                                window.location.replace('/login');
                                this.spinner.hide();
                            }
                        }
                        else if (err.status === 500) {
                            if (err.error) {
                                this._toastr.error(err.error.error.msg, 'Something went wrong!');
                                this.spinner.hide();
                            }
                        } else {
                            this.spinner.hide();
                            this._toastr.error('Please check your internet connection.');
                            return;
                        }
                    }
                    return of(err);
                })
            );
        }
        else {
            this.spinner.hide();
            const updatedRequest = request.clone({
                headers: new HttpHeaders({
                    Accept: 'application/json',
                    appId: '3',
                    language: sessionStorage.getItem('language_type') ? sessionStorage.getItem('language_type') : localStorage.getItem('language_type')
                })
            });
            return next.handle(updatedRequest).pipe(
                tap((event: HttpEvent<any>) => {
                    let err_msg = '';
                    this.spinner.show();
                    if (event instanceof HttpResponse) {
                        if (event.body && event.body.success == true) {
                            this.spinner.hide();
                        }
                        if (event.body && event.body.success == false) {
                            this.spinner.hide();
                            err_msg = event.body.error.msg;
                            this._toastr.error(err_msg);
                        }
                        if (event.body && event.body.FREE) {
                            this.spinner.hide();
                        }
                    }
                }), catchError((err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 400) {
                            if (err.error) {
                                this._toastr.error(err.error.error.msg);
                                this.spinner.hide();
                            }
                        }
                        else if (err.status === 401) {
                            localStorage.clear();
                            if (!environment.production) {
                                window.location.replace('/login');
                                this.spinner.hide();
                            }
                        }
                        else if (err.status === 500) {
                            if (err.error) {
                                this._toastr.error(err.error.error.msg, 'Something went wrong!');
                                this.spinner.hide();
                            }
                        } else {
                            this.spinner.hide();
                            this._toastr.error('Please check your internet connection.');
                            return;
                        }
                    }
                    return of(err);
                })
            );
        }
    }
}
